.updates-layout {
  padding-top: rem-calc(65);
  .card {
    .card-divider {
      .h3 {
        position: relative;
        top: 3px;
      }
      .is_expanded {
        .icon-chevron-down {
          &:before {
            content: '\25b2';
          }
        }
      }
    }
    .updates-list {
      padding-top: 5px;
      padding-bottom: 10px;
    }
    .update-item {
      padding-top: rem-calc(26);
      padding-bottom: rem-calc(22);
      & + .update-item {

        border-top: 1.5px solid rgba($dark-gray, 0.1);
      }
      .cell {
        :last-child {
          margin-bottom: 0;
        }
      }
    }
    #events_expanded {
      border-top: 1.5px solid rgba($dark-gray, 0.1);
    }
  }
    .block__news {
        width: 100%;
    }
  .updates-list {
      width: 100%;
    .date {
      margin-top: rem-calc(4);
      border-radius: rem-calc(10);
      border: 2px solid $dark-gray;
      color: $dark-gray;
      text-align: center;
      display: inline-block;
      font-size: 1.2rem;
      line-height: 1.2rem;
      padding: 0.5rem;
      @include breakpoint(medium) {
        padding: rem-calc(16 16 0);
        width: rem-calc(120);
        height: rem-calc(120);
        font-size: rem-calc(30);
        line-height: rem-calc(28);
      }
      font-weight: 700;
      .month {
        text-transform: lowercase;
      }
      .year {
        margin-top: rem-calc(6);
        font-weight: 300;
        font-size: rem-calc(20);
      }
    }
    .dotted-line {
      background-image: url(../images/svg/dot-line-dark-vertical.svg);
      background-repeat: repeat-y;
      background-size: 4px 11px;
      position: absolute;
      top: 99px;
      bottom: rem-calc(10 - 40px);
      @include breakpoint(medium) {
        top: 139px;
        bottom: rem-calc(10 - 80px);
      }
      @include breakpoint(large) {
        top: 139px;
        bottom: rem-calc(10 - 170px);
      }
      //left: 50%;
      //margin-left: -2px;
      left: calc(25% / 2);
      @include breakpoint(large) {
        left: calc(16.66667% / 2);
      }
      width: 4px;
    }
    .update-text {
      //padding-bottom: 40px;
      @include breakpoint(medium) {
        //padding-bottom: 170px;
      }
    }

    .title {
      margin-bottom: rem-calc(18);
      a {
        color: inherit;
        &:hover {
          color: $black;
        }
      }
    }


  }
  .block__news {
    .update-item {
      .update-content {
          width: 100%;
        padding-bottom: 40px;
        @include breakpoint(medium) {
          padding-bottom: 80px;
        }
        @include breakpoint(large) {
          padding-bottom: 170px;
        }
      }
      &.first {
        //background: red;
        .update-content {
          margin-top: 70px;
          @include breakpoint(medium) {
            margin-top: 120px;
          }
          @include breakpoint(large) {
            margin-top: 170px;
          }
        }
        .dotted-line.first {
          bottom: auto;
          top: -60px;
          height: 60px;
          @include breakpoint(medium) {
            top: -110px;
            height: 110px;
          }
          @include breakpoint(large) {
            top: -160px;
            height: 160px;
          }

        }
      }
    }
  }
}