[data-block] {
  .block__title {
    text-align: center;
    position: relative;
    margin-bottom: 75px;
    @include breakpoint(large) {
      margin-bottom: 125px;
    }

    h2 {
      display: inline-block;
      font-size: rem-calc(30);
      @include breakpoint(large) {
        font-size: rem-calc(50);
      }
    }

    &:after {
      content: '';
      @include horizontal-center();
      bottom: -25px;
      @include breakpoint(large) {
        bottom: -55px;
      }
      width: 116px;
      height: 4px;
      background: url(../images/svg/dot-line-dark.svg);
      background-position: center center;
      background-repeat: repeat-x;
      background-size: 11.1px 4px;
    }
  }
}
