@import "footer";
@import "google-map";
@import "logo-list";
@import "cards";
@import "block-titles";
@import "illustrations";
@import "accordion";
@import "downloads";
@import "maintenance";
@import "map";

#newsletterModal {
	@include reveal-modal-width(30%, 400px);
}