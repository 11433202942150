.block__accordion {
    .accordion {
        margin-bottom: 0;
        border-radius: $global-radius;
        box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.16);

        .accordion-title {
            font-weight: 700;
            position: relative;
            padding: rem-calc(24 40 24 20);
            font-size: 1rem;
            @include breakpoint(large) {
                font-size: 1.25rem;
                padding: 1.5rem rem-calc(50);
            }
            @include transition(all);

            &:after {
                @include transition(all);
                content: '▼';
                font-family: opjestek, sans-serif;
                position: absolute;
                right: 17px;
                top: 27px;
                font-size: 15px;
                @include breakpoint(large) {
                    right: 37px;
                }
            }

            &:hover,
            &:focus {
                color: $black;
            }
        }

        .accordion-content {
            padding: rem-calc(0 20 16);
            @include breakpoint(large) {
                padding: 0 3.125rem 1rem;
            }

            p {
                margin-bottom: 1rem;
            }

            :last-child {
                margin-bottom: 0;
            }
        }

        .accordion-item {
            @include transition(all);

            a{
                &[aria-expanded='true']{
                    &:after {
                        content: '▲'
                    }
                }
            }
            & + .accordion-item {
                border-top: 1px solid rgba($medium-gray, 0.3);
            }
        }
        &-categories{
            .accordion-content{
                padding-bottom: 40px;
                .accordion-content{
                    padding: 0px 40px 20px 20px;
                }
            }
            .accordion{
                border-radius: 10px;
                box-shadow: none;
                border: 1px solid rgba($medium-gray, 0.3);
                .accordion-title {
                    padding:20px;
                    font-size: rem-calc(16);
                    &:after {
                        top: 22px;
                        right: 17px;
                    }
                }
            }
        }
    }
}
