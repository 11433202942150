[data-block=content-usp-list] {
  .cell {
    @include card-container();
    @include card-section();
    padding: 10px 20px 20px;
    @include breakpoint(small) {
      margin-bottom: 20px;
      padding: 20px 30px 20px;
    }
    @include breakpoint(large) {
      padding: 40px 50px 50px;
    }
    .item-image {
      position: relative;
      min-height: 90px;
      img {
        position: absolute;
        bottom: 0;
      }
    }
    h3 {
      font-size: rem-calc(30);
      position: relative;
      margin-top: 24px;
      margin-bottom: 34px;
      &:after {
        content: '';
        left: 0;
        position: absolute;
        bottom: -20px;
        width: 54px;
        height: 4px;
        background: url(../images/svg/dot-line-dark.svg);
        background-position: center center;
        background-repeat: repeat-x;
        background-size: 8px 3px;
      }
    }
  }
}