[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{
	margin-bottom: 0;
	-webkit-appearance: none;

	@include breakpoint(small only) {
		padding: 0.725rem 1rem;
		height: auto;
	}
}

.form-group{
	margin-bottom: rem-calc(20px);
}

.is-invalid-input:not(:focus) {
	border-color: #EF4036;
	background-color: $white;
}
textarea {
	min-height: 200px;
	resize: vertical;
}
input:disabled, input[readonly], textarea:disabled, textarea[readonly] {
	background: $white;
}
.block__renatioform {
	.form__outer-container {
		@include card-container();
		@include card-section();
	}
}
.form-control.valid {
	border-color: $primary-color;
}
form.sending {
	.form-group {
		opacity: 0.5;
	}
}
.form__container {
	position: relative;
	z-index: 0;
	.form__loader {
		z-index: -1;
		opacity: 0;
		background-color: rgba($white, 0.7);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		@include transition(all);
		svg {
			width: 50px;
			height: 50px;
			@include horizontal-center();
			top: 30%;
		}
	}
	&.sending {
		.form__loader {
			z-index: 1;
			opacity: 1;
		}
	}
}
.form-group {
	.radiogroup-label {
		font-weight: 700;
		color: inherit;
		font-size: 1.25rem;
		margin-bottom: 0.8rem;
	}
	.radio-inline {
        font-size: initial;
		input {
			visibility: hidden;
			width: 0;
			height: 0;
			position: absolute;
			z-index: -45;
		}
		span {
			cursor: pointer;
			display: inline-block;
			margin-bottom: 10px;
			padding-left: 40px;
			position: relative;
			&:before {
				content: '';
				width: 20px;
				height: 20px;
				position: absolute;
				left: 0;
				top: 5px;
				border-radius: 100%;
				border: 2px solid #D1D2D4;
				@include transition(all);
			}
			&:hover {
				&:before {
					border-color: #b6bbbe;
					transform: scale(1.2);
				}
			}
			&:after {
				content: '';
				width: 10px;
				height: 10px;
				position: absolute;
				left: 5px;
				top: 10px;
				border-radius: 100%;
				background-color: transparent;
			}
		}
		input:checked {
			& + span {
				&:after {
					background-color: #D1D2D4;
				}
			}
		}
        &.is-invalid-label {
            span {
                &:before {
                    border-color: #EF4036;
                }
            }
        }
	}
}