.block__counter {
  .card {
    padding: rem-calc(20);
    margin-bottom: rem-calc(20);
    @include breakpoint(large) {
      padding: rem-calc(36 20 25);
    }
  }
  @include breakpoint(medium) {
    .offset-small {
      padding-top: 20px;
    }
    .offset-large {
      padding-top: 40px;
    }
  }
  @include breakpoint(large) {
    .offset-small {
      padding-top: 20px;
    }
    .offset-large {
      padding-top: 125px;
    }
  }
  .counter {
    font-weight: bold;
    font-size: rem-calc(48);
    margin-bottom: rem-calc(10);
    @include breakpoint(medium) {
      font-size: rem-calc(60);
      margin-bottom: rem-calc(10);
    }
    @include breakpoint(large) {
      font-size: rem-calc(90);
      margin-bottom: rem-calc(20);
    }
  }
}