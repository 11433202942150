body.showBlockNames [data-block]:before {
  content: attr(data-block);
  position: absolute;
  left: 0;
  background-color: green;
  color: white;
  padding: 4px;
  z-index: 99999;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.dp-block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.uppercase {
  text-transform: uppercase;
}

.valign-middle {
  vertical-align: middle;
}