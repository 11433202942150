.architect-list {
  .architect-tile {
    display: block;
    border-radius: $global-radius;
    overflow: hidden;
    color: $white;
    cursor: pointer;
    @include box-shadow($global-shadow);
    @include breakpoint(medium down) {
      margin-bottom: .9375rem;
    }
    &.red {
      background-color: #EF4136;
      .logo {
        background-color: #EF4136;
      }
    }
    &.blue {
      background-color: #ADDFE9;
      .logo {
        background-color: #ADDFE9;
      }
    }
    &.green {
      background-color: #7BBA60;
      .logo {
        background-color: #7BBA60;
      }
    }
    &.target-tile {
      position: relative;
      height: calc(100% - 1.875rem);
      cursor: initial;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      transition-property: background-image;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      &:after {
        content: '';
        height: 50%;
        width: 40%;
        position: absolute;
        border-radius: 0px $global-radius 0px 0px;
        left: 0px;
        bottom: 0px;
        opacity: 0.7;
      }
      &.green:after {
        background-color: #7BBA60;
      }
      &.blue:after {
        background-color: #ADDFE9;
      }
      &.red:after {
        background-color: #EF4136;
      }
    }
    .photo, .no-photo {
      position: relative;
      height: 200px;
      width: 100%;
    }
    .photo {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .no-photo {
      background-color: grey;
    }
    &:hover {
      .logo {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        img {
          opacity: 1;
          transition-delay: 0.2s;
        }
      }
    }
    .logo {
      overflow: hidden;
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 0%;
      transition-property: height;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      img {
          max-height: 80%;
          max-width: 80%;
          width: auto;
          height: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          opacity: 0;
          transition-delay: 0s;
          transition-property: opacity;
          transition-duration: 0.2s;
          transition-timing-function: ease-in-out;
        }
    }
    h3 {
      color: $white;
      font-size: rem-calc(22);
      margin: 1rem 1.5rem;
    }
  }
}

.block__architects {
  .grid-margin-x {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.close-button-wrapper {
  width: 100%;
  height: 45px;
  position: relative;
  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: rem-calc(15);
    font-weight: bold;
    .red-button {
      background-color: #EF4036;
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 25px;
      text-align: center;
      font-size: rem-calc(32);
      color: $white;
      border-radius: 3px;
      font-weight: bold;
      margin-left: 10px;
    }
  }
}


@include breakpoint(large up) {
  .reveal.architect-modal {
    border: 0;
    padding: 0px;
    width: 1000px;
    .photo, .no-photo {
      height: 480px;
      width: 100%;
    }
    .photo {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .no-photo {
      background-color: grey;
    }

    .left {
      background-color: #7BBA60;
      color: $white;
      padding-bottom: 60px;
      text-align: center;
      max-height: 935px;
      overflow: hidden;
      img {
        padding-top: 20px;
        max-width: 50%;
      }
      h3 {
        padding: 20px 0px;
      }
      p {
        margin-bottom: 0px;
      }
      a {
        color: $white;
      }
    }
    .right {
      position: relative;
      .video-wrapper {
        height: 440px;
        padding: 20px 20px 40px 20px;
        video {
          border-radius: $global-radius;
          width: 100%;
          height: 400px;
        }
        iframe {
          border-radius: $global-radius;
          width: 100%;
          height: 100%;
        }
      }
      .content-gradient {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 100px;
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      }
      .content-with-overflow {
        height: 450px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 20px;
        padding-bottom: 40px;
        &.full-height {
          height: 890px;
        }
      }
    }

    .navigation {
      background-color: #ADDFE9;
      height: rem-calc(60);
    }

    .next-button, .previous-button {
      cursor: pointer;
      position: absolute;
      color: $white;
      font-size: 1rem;
      font-weight: bold;
      .arrow {
        font-size: 2.5rem;
        position: relative;
        top: 4px;
        display: inline-block;
        width: 36px;
        height: 22px;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    .next-button {
      right: 40px;
      bottom: 20px;
      .arrow {
        background-image: url('/themes/kitchen/assets/dist/images/arrow-right.png');
        margin-left: 5px;
      }
    }

    .previous-button {
      left: 40px;
      bottom: 20px;
      .arrow {
        background-image: url('/themes/kitchen/assets/dist/images/arrow-right.png');
        margin-right: 5px;
        transform: rotate(180deg);
      }
    }
  }
}

@include breakpoint(medium down) {
  .reveal.architect-modal {
    padding: 0px;
    width: 100%;
    border: 0;
    border-radius: 0;
    .photo, .no-photo {
      height: 280px;
      width: 100%;
    }
    .photo {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .no-photo {
      background-color: grey;
    }

    .left {
      background-color: #7BBA60;
      color: $white;
      text-align: center;
      padding-bottom: 10px;
      img {
        padding-top: 20px;
        max-width: 50%;
      }
      h3 {
        padding: 20px 0px;
      }
      p {
        margin-bottom: 0px;
      }
      a {
        color: $white;
      }
    }

    .right {
      .video-wrapper {
        height: 300px;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .content-gradient {
        display: none;
      }
      .content-with-overflow {
        max-height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 10px;
      }
    }
    .next-button, .previous-button, .navigation {
      display: none;
    }
  }
}

#architectSearchArea {
  width: 100%;
  position: relative;
  border: 2px solid #42454A;
  background-image: url('/themes/kitchen/assets/dist/images/search-icon.png');
  background-repeat: no-repeat;
  background-position: 20px center;
  padding-left: 60px;
  &::placeholder {
    color: #42454A;
  }
}
