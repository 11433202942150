ul,ol{
	li,a{
		font-family: $body-font-family;
		color:$body-font-color;
		font-size: rem-calc(16);
	}
}
ul.extraspace {
	li {
		margin-bottom: 30px;
	}
}
/*ul {
	&:not(.menu),
	&:not(.slick-dots)
	{
		margin-top: 10px;
		> li {
			padding-left: rem-calc(10);
			&+li {
				padding-top: rem-calc(26);
			}
		}
	}
}*/