$spacers: (
				'10' : 10,
				'20' : 20,
				'30' : 30,
				'40' : 40,
				'50' : 50,
				'75' : 75,
				'100' : 100,
				'200' : 200,
);
@each $spacerSize, $spacerValue in $spacers {
	.spacer--#{$spacerSize}-px{
		height: #{$spacerValue}px;
	}
	.spacer--#{$spacerSize}-vh{
		height: #{$spacerValue}vh;
	}
}
$spacersTextLarge: (
				'xx-large' : 300,
				'x-large' : 200,
				'large' : 100,
				'medium' : 75,
				'small' : 50,
);
$spacersTextSmall: (
				'xx-large' : 150,
				'x-large' : 100,
				'large' : 75,
				'medium' : 50,
				'small' : 20,
);
@each $spacerSize, $spacerValue in $spacersTextSmall {
	.spacer--#{$spacerSize} {
		height: #{$spacerValue}px;
	}
}
@include breakpoint(large) {
	@each $spacerSize, $spacerValue in $spacersTextLarge {
		.spacer--#{$spacerSize} {
			height: #{$spacerValue}px;
		}
	}
}

.container-spacer{
	height: rem-calc(200);
}