.block__ctaFull {
  > .grid-container {
    max-width: none;
    padding: 0;
  }
  .cta {
    &--image {
      position: relative;
      padding-top: 70px;
      border-radius: $global-radius;
      @include cover-bg();
      background-position: center center;
      background-repeat: no-repeat;
      margin: 0.625rem;
      @include breakpoint(medium) {
        min-height: 500px;
      }
      @include breakpoint(large) {
        margin: 38px;
      }
      margin-bottom: 85px;
      //background: $tertiary-color;
      .card {
        margin-bottom: -85px;
      }
    }

    .corner {
      position: absolute;
      border-radius: $global-radius;
      opacity: 0.8;
    }

    .leftcorner {
      width: 40%;
      height: 55%;
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
      bottom: 0;
      left: 0;
      background: $tertiary-color;


    }

    .rightcorner {
      width: 14.5%;
      height: 65%;
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
      top: 0;
      right: 0;
      background: $primary-color;
    }
    .title {
      @include breakpoint(large) {
        line-height: rem-calc(54);
      }
      margin-bottom: 1rem;
    }
    .subtitle {
      @include breakpoint(large) {
        line-height: rem-calc(44);
      }
    }
    .buttons {
      margin-top: rem-calc(40);
    }
  }
}
.contentpage-content {
  [data-block].block__ctaFull > .grid-container {
    padding-left: 0;
    padding-right: 0;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
  }
}