.card {
  margin-bottom: 0;
  &-divider {
    color: $white;
    font-weight: 700;
    padding: rem-calc(10 30);
    a {
      font-size: rem-calc(18);
      font-weight: 300;
      color: $white;
      .icon {
        margin-left: 25px;
      }
    }
    .grid-x {
      width: 100%;
    }
  }
  &-section {
    padding: 30px;
  }
}