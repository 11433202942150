//Sticky footer
/*.page__container{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}
.page__content{
	flex: 1 0 auto;
}

footer {
	flex-shrink: 0;
}*/
@include breakpoint(large) {
	.contentpage-content {
		max-width: $global-width;
		margin: 0 auto;

		[data-block] {
			> .grid-container {

				//$gutter: map-get($grid-column-gutter, medium);
				padding-right: calc(41.66667% - (6 * 15px));

				.grid-x {
					.cell {
						//background: $primary-color;
					}
				}
			}
			> .block__title {
				margin-right: calc(41.66667% - (6 * 15px));
			}
		}
		&.no-submenu [data-block] > .grid-container {
			padding-right: .9375rem;
		}
	}
}



