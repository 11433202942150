.full-carousel{

	@include breakpoint(medium down) {
		padding: 0.625rem;
		position: relative;
		padding-bottom: 66.67%;
		height: 0;
		width: 100%;
		margin-bottom: 2.25rem;
		.full-carousel__slides {
			position: absolute;
			left: 0.625rem;
			right: 0.625rem;
			top: 0.625rem;
			bottom: 1.25rem;

		}
	}
	@include breakpoint(small only) {
		padding-bottom: 75%;
	}
	@include breakpoint(large) {
		height: 100vh;
		padding: 38px;
	}
	position: relative;
	&__slides {
		margin-bottom: 0;
		height: 100%;
		@include border-radius($global-radius);
		overflow: hidden;
		box-shadow: 5px 5px 15px rgba(0,0,0,0.16);
		@include linear-gradient--vertical(rgba(0,0,0,0.1), rgba(0,0,0,0.1));
		.full-carousel__slide {
			@include cover-bg;
			height: 100%;
			position: relative;
			background-position: center center;
			.gradient {
				width: 100%;
				height: 100%;
				@include linear-gradient--vertical(rgba(0,0,0,0.1), rgba(0,0,0,1));
				opacity: 0.3;
				cursor: grab;
				&:active {
					cursor: grabbing;
				}
			}
			opacity: 0;
			@include transition(all);
		}

		&.slick-initialized {
			.full-carousel__slide {
				@include fadeIn(500ms, 100ms);
			}
		}
	}
	.slick-list,
	.slick-track {
		height: 100%;
	}
	&__content {
		position: absolute;
		height: 100%;
		//top: 20%;
		//transform: translateY(-50%);
		padding: 0 20px;
		@include breakpoint(large) {
			height: 0;
			top: 35vh;
			padding: 0;
		}
		left: 0;
		right: 0;
		&__slides {
			opacity: 0;
			height: 100%;
			@include breakpoint(large) {
				height: auto;
			}
			@include transition(all);
			&.slick-initialized {
				@include fadeIn(250ms, 700ms);
			}
		}

		&__slide {
			cursor: grab;
			&:active {
				cursor: grabbing;
			}
			.grid-container {
				position: relative;
				z-index: 5;
				height: 100%;
				@include breakpoint(large up) {
					padding-left: 80px;
					padding-right: 80px;
				}
				@include breakpoint(1560px) {
					max-width: rem-calc(1464);
					padding-left: 0.9375rem;
					padding-right: 0.9375rem;
				}
				.grid-x {
					@extend .align-middle;
					height: 100%;
				}
			}

			color: $white;

		}
	}
	&__pagination{
		@include breakpoint(medium down){
			display: none !important;
		}
		@include breakpoint(large){
			height: 50px;
		}
		@include vertical-center;
		right: 50px;
		list-style-type: none;
		li,span{
			margin:rem-calc(0 5px);
			padding: 14px;
			display: block;
			cursor: pointer;
			font-size: 0;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				width: rem-calc(12.29);
				height: rem-calc(12.29);
				background: $white;
				opacity: 0.2;
				border-radius: 100%;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
			&.slick-active {
				&:before {
					opacity: 1;
				}
			}
		}
	}
	h1 {
		font-size: rem-calc(30);
		line-height: rem-calc(34);
		margin-bottom: 0;
		@include breakpoint(medium down) {
			br {
				//display: none;
			}
		}
		@include breakpoint(medium) {
			font-size: rem-calc(50);
			line-height: rem-calc(54);
		}
		@include breakpoint(1140) {
			font-size: rem-calc(80);
			line-height: rem-calc(84);
		}
	}
	h2 {
		font-size: rem-calc(28);
		line-height: rem-calc(28);
		margin-bottom: rem-calc(10);
		@include breakpoint(medium) {
			font-size: rem-calc(40);
			line-height: rem-calc(40);
			margin-bottom: rem-calc(40);
		}
		@include breakpoint(1140) {
			font-size: rem-calc(70);
			line-height: rem-calc(74);
			margin-bottom: rem-calc(40);
		}
	}

	.button {
		display: none;
	}
	@include breakpoint(medium) {
		.button {
			display: block;
			float: left;

			& + .button {
				margin-left: rem-calc(20);
			}
		}
	}
	.tobottom {
		@include breakpoint(small only) {
			display: none;
		}
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: rem-calc(60);
		color: $white;
		font-size: rem-calc(30);

	}
}