$white: #FFFFFF;
$black: #000000;
$primary-color: #7BBA60;
$secondary-color: #EF4036;
$tertiary-color: #ADDFE9;
$springwood: #FAF7F4;
$header-color: $tertiary-color;
$light-gray: #DEDFE1;
$medium-gray: #D1D2D4;
$dark-gray: #42454A;
$global-shadow: 5px 5px 15px rgba(0,0,0,0.16);
$colors: (
        'white' : $white,
        'black' : $black,
        'green' : $primary-color,
        'red' : $secondary-color,
        'blue' : $tertiary-color,
        'dark-gray': $dark-gray,
        'medium-gray': $medium-gray
);