.map {
  svg {
    display: block;
  }
  a {
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  }
  .st0, .st1 {
    //fill: transparent;
    //stroke: none;
    //opacity: 0;
  }
  .is-interactive {
    //fill: green;
    //cursor: pointer;
    &:hover {
      fill: $secondary-color;
      opacity: 0.57;
    }
  }
}
.map-cards {
  padding-top: rem-calc(40px);
  @include breakpoint(large) {
    position: absolute;
    top: 0;
    right: 0;
    width: 140%;
  }
  .card {
    position: relative;
    @include breakpoint(large) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
    .card-section {
      display: block;
    }
    @include transition(all);
    .subtitle {
      margin-bottom: 0;
    }
    .title {
      margin-bottom: rem-calc(12px);
    }

    &-is-hidden {
      opacity: 0;
      z-index: -1;
      visibility: hidden;
      display: none;
    }
    &-is-visible {
      opacity: 1;
      z-index: 1;
      visibility: visible;
      display: block;
    }
  }
}
@include breakpoint(medium down) {
  .sticky-wrapper {
    height: auto !important;
  }
  .sticky-element {
    top: 0 !important;
  }
}