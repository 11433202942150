.card-slider {
  .card-content {
    position: relative;
  }

  .control {
    position: absolute;
    z-index: 1;
    color: $dark-gray;
    font-size: 30px;
    position: absolute;
    top: 80px;
    @include breakpoint(medium) {
      @include vertical-center();
    }

    &.button-prev {
      left: rem-calc(26);
      @include breakpoint(large) {
        left: rem-calc(46);
      }
    }

    &.button-next {
      right: rem-calc(26);
      @include breakpoint(large) {
        right: rem-calc(46);
      }
    }

    &.slick-disabled {
      color: $medium-gray;
      cursor: default;
    }
  }

  .slides {
    position: relative;
    z-index: 0;
  }

  .slide {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
    @include breakpoint(medium down) {
      .grid-x {
        align-items: start;
      }
    }

    &__illustration {
      //min-height: 200px;
      @include breakpoint(large) {
        //min-height: 295px;

        position: relative;
        img {
          display: inline-block;
          /*position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);*/

        }
      }
    }

    &__text {
      //padding-top: rem-calc(50);
      //padding-bottom: rem-calc(60);
      @include breakpoint(large) {
        padding-top: rem-calc(50);
        padding-bottom: rem-calc(60);
      }

      .title {
        margin-bottom: rem-calc(18);
      }

    }
  }

  .slick-dots {
    white-space: nowrap;
    li {
      margin: 0;
      width: 30px;
      height: 30px;
      position: relative;

      button {
        font-size: 15px;
        font-weight: 700;
        line-height: 10px;
        color: $medium-gray;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background: $white;
        border: 2px solid $medium-gray;
        @include transition(all);

        &:hover, &:focus {
          color: $dark-gray;
          border-color: $dark-gray;
        }

        &:before {
          content: '';
        }
      }

      & + li {
        margin-left: 41px;
        @include breakpoint(large) {
          margin-left: 75px;
        }

        &:before {
          @include transition(all);
          content: '';
          position: absolute;
          left: -35px;
          width: 32px;
          @include breakpoint(large) {
            left: -72px;
            width: 64px;
          }
          top: 50%;
          margin-top: -2px;
          height: 4px;
          background: url(../images/svg/dot-line-dark.svg);
          background-position: center left;
          background-repeat: repeat-x;
          background-size: 11px 4px;
        }
      }

      &.slick-active {
        button {
          background: $dark-gray;
          border-color: $dark-gray;
          color: $white;
          transform: scale(1.2);
        }

        & + li {
          &:before {
            transform: scale(1.08);
            left: -35px;
            @include breakpoint(large) {
              left: -69px;
            }
          }
        }
      }
    }
  }
}