.topbar {
  &__container {
    z-index: 50;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    @include breakpoint(medium down) {
      height: 60px;
      position: relative;
    }
    .grid-container {
      max-width: none;
    }
  ;
  }
  .logo {
    display: inline-block;
    @include breakpoint(medium down) {
      margin-left: 10px;
      margin-top: 14px;
    }
    svg {
      width: 62px;
      height: 31px;
      @include breakpoint(large) {
        width: 118px;
        height: 60px;
        @include transition(all);
      }
    }
  }
  &__wrapper {
    //padding: 80px 30px 0;
    @include transition(all);
    @include breakpoint(medium down) {
      position: fixed !important;
      top: 0 !important;
      width: 100%;
      background: $primary-color;
      padding: 0;
      height: 60px;
      box-shadow: 0 -25px 20px 10px #000000;
    }
    @include breakpoint(large) {
      background: transparent;


    }
    @include breakpoint(large) {
      padding: 80px 57px 0 55px;
    }
    @include breakpoint(1140) {
      padding: 80px 57px 0 55px;
    }
    @include breakpoint(1320) {
      padding: 80px 72px 0 72px;
    }

    &.is-stuck {
      @include breakpoint(medium down) {
        box-shadow: 0 -15px 20px 10px #000000;
      }
      @include breakpoint(large) {
        padding: 10px 57px 10px 55px;
      }
      @include breakpoint(1140) {
        padding: 10px 57px 10px 55px;
      }
      @include breakpoint(1320) {

        padding: 10px 82px 10px 82px;
      }
      @include breakpoint(large) {
        background: rgba(255, 255, 255, 0.8);
      }
      @include breakpoint(large) {
        .logo {
          svg {
            transform: translateY(3px);
            width: 100px;
            height: 51px;

            .fill {
              fill: $dark-gray;
            }
          }
        }

        .button {
          padding: 1em 1.8em;
        }
      }
    }
  }

  .menu-wrapper {


    .menu {
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      float: left;
      margin-top: 10px;
      > li {
        & + li {
          @include breakpoint(1140) {
            margin-left: rem-calc(7);
          }
          @include breakpoint(xlarge) {
            margin-left: rem-calc(10);
          }
        }
      }
      a {
        //height: rem-calc(62.75);
        color: $white;
        font-weight: 700;
        padding: 0.7rem 0.5rem;

        @include breakpoint(1140) {
          padding: 0.7rem 0.7rem;
        }
        @include breakpoint(xlarge) {
          padding: 0.7rem 1rem;
        }
        position: relative;

        &:after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 50%;
          right: 50%;
          height: 4px;
          background-image: url(../images/svg/dot-line.svg);
          background-position: center center;
          background-repeat: repeat-x;
          background-size: 11px 4px;
          opacity: 0;
          @include transition(all, 250ms);
        }

        &:hover {
          &:after {
            left: 1rem;
            right: 1rem;
            opacity: 1;
          }
        }
      }

      .is-active {
        a {
          background: transparent;
          &:after {
            left: 1rem;
            right: 1rem;
            opacity: 1;
          }
        }
      }

    }

    .button {
      float: right;
      margin-left: rem-calc(10);
      padding: 1em 1em;
      @include breakpoint(1140) {
        margin-left: rem-calc(10);
      }
    }

    .search-icon {
      color: #fff;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 20px 20px;
      padding: 1em 1.5em;
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' fill='white' viewBox='0 0 56.966 56.966' style='enable-background:new 0 0 56.966 56.966;' xml:space='preserve'%3E%3Cpath d='M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23 s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92 c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17 s-17-7.626-17-17S14.61,6,23.984,6z'/%3E%3C/svg%3E");
    }
  }
  &__wrapper {
    &.is-stuck {
      .menu {
        a {
          color: $dark-gray;
          &:after {
            background-image: url(../images/svg/dot-line-dark.svg);
          }
        }
      }
    }
  }
}

.svg-menu-toggle {

  fill:#fff;
  pointer-events:all; //needs to be there so the hover works
  cursor:pointer;

  .bar {

    -webkit-transform:rotate(0) translateY(0) translateX(0);
    transform:rotate(0) translateY(0) translateX(0);
    opacity:1;


    -webkit-transform-origin: 20px 10px;
    transform-origin: 20px 10px;

    -webkit-transition: -webkit-transform 0.4s ease-in-out, opacity 0.2s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;

  }
  .bar:nth-of-type(1) {
    -webkit-transform-origin: 20px 10px;
    transform-origin: 20px 10px;

  }
  .bar:nth-of-type(3) {
    -webkit-transform-origin: 20px 20px;
    transform-origin: 20px 20px;

  }


}
.menu-button {
  width: 40px;
  height: 40px;
  border-radius: 0;
  padding: 0;
  position: relative;
  right: 16px;
  top: 7px;
  .menu-icon {
    position: absolute;
    left: 11px;
    top: 9px;
    span {
      opacity: 1;
      display: block;
      width: 18px;
      height: 2px;
      border-radius: 2px;
      background: $white;
      margin: 4px 0;
      @include transition(all);
    }
  }
  .icon {
    position: absolute;
    top: 12px;
    left: 12px;
    color: $white;
    opacity: 0;
    @include transition(all);
  }
}
.off-canvas-content {
  transition: all 0.5s ease;
}
.off-canvas.position-right {
  &:after {
    content: '';
    position: absolute;
    left: -80px;
    z-index: -1;
    top: 0;
    bottom: 0;
    width: 80px;
    @include linear-gradient--horizontal(rgba(0,0,0,0), rgba(0,0,0,0.19));
    pointer-events: none;
  }

  padding-top: 70px;
}
.is-off-canvas-open {
  .menu-button {
    .menu-icon {
      span {
        opacity: 0;
      }
    }

    .icon {
      opacity: 1;
    }
  }
  .off-canvas-content {
    //-webkit-filter: blur(5px);
    //filter: blur(5px);
    transform: translateX(-150px);
  }
}
.off-canvas {
  .menu-wrapper {
    ul {
      margin: 0;
      font-size: rem-calc(18);
      font-weight: 700;
      list-style: none;
      li {
        > ul {
          margin-left: 20px;
          margin-top: 10px;
          margin-bottom: 10px;
          font-weight: 400;
        }
        & + li {
          padding-top: 5px;
        }
      }
      a {
        color: $dark-gray;
        display: block;
        padding: 5px 10px;
      }
      > li.active {
        > a {
          color: $primary-color;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 10px;
            width: 42px;
            height: 4px;
            background-image: url(../images/svg/dot-line-dark.svg);
            background-position: left center;
            background-repeat: repeat-x;
            background-size: 11px 4px;
            opacity: 1;
            @include transition(all, 250ms);
          }
        }
      }
    }
  }
}
