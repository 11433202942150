$icomoon-font-family: "opjestek" !default;
$icomoon-font-path: "fonts" !default;

$icon-checkmark: "\2713";
$icon-cross: "\10102";
$icon-download: "\1f4be";
$icon-facebook: "\e903";
$icon-instagram: "\e904";
$icon-linkedin: "\e905";
$icon-minus: "\2d";
$icon-plus: "\2b";
$icon-twitter: "\e908";
$icon-whatsapp: "\e909";
$icon-arrow-up: "\2b06";
$icon-arrow-down: "\2b07";
$icon-arrow-left: "\2b05";
$icon-arrow-right: "\27a1";
$icon-chevron-up: "\25b2";
$icon-chevron-down: "\25bc";

