.maintenance{
	height: 100vh;
	width:100%;
	display: flex;
	text-align: center;
	align-items: center;
	div{
		width: 100%;
	}
	img{
		padding-left: 100px;
	}
	p{
		font-size: 16px;
    	font-style: italic;
    	font-weight: 600;
    	letter-spacing: 2px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}