.inspiration {

  &__list {

  }

  &__item {
    @include card-container();
    &-content {
      @include card-section();
    }
    &.no-image {
      background-color: $primary-color;
    }
  }
  &__reveal {
     &-reactions {
      padding: 40px 30px 40px 50px;
      border-top: 1px solid $medium-gray;
      h3 {
        cursor: pointer;
        .icon {
          font-size: 18px;
        }
      }
    }
  }
  .sidebar {
    .spacer--medium {
      display: none;
    }
    .downloads {
      .button {
        background: $white;
        color: $dark-gray;
        &:hover, &:focus {
          background-color: darken($white,5%);
        }
      }
    }
  }
}
.inspiration__reveal {
  padding: 0;
  border: 0;
  @include reveal-modal-width(80%, 810px);

  &-content {
    display: block;
    padding: 40px 30px 20px 50px;

    .item-title {
      font-size: 40px;
    }
    &.has-border {
      border-bottom: 1px solid $medium-gray;
    }
  }

  .close {
    position: absolute;
    right: 30px;
    top: 30px;
    background: $secondary-color;
    color: $white;
    font-size: 11px;
    cursor: pointer;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;

    i {
      vertical-align: middle;
    }
  }
  .control-multi-file-uploader {
    //z-index: 1;
    .content {
      height: 0;
      padding: 0 0 100%;
      //position: relative;
      z-index: 0;
      background: #EBEBEB;
      border-radius: 5px;

      p.placeholder {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 700;
        text-align: center;
        color: #D1D2D4;
        margin-top: rem-calc(40);
        pointer-events: none;
      }
      &:before {
        @include absolute-center();
        content: '\2b';
        color: $white;
        background: $dark-gray;
        border-radius: 5px;
        width: rem-calc(45);
        height: rem-calc(45);
        text-align: center;
        line-height: rem-calc(45);
        font-size: rem-calc(18);
        font-family: opjestek, sans-serif;
        pointer-events: none;
      }
    }
    .clickable {
      border: none;
      //z-index: 2;
    }

  }
}
