.button {
	font-weight: 700;
	border: rem-calc(1.5px) solid transparent;


	@include breakpoint(small only) {
		font-size: rem-calc(14);
		padding: 1.16em 1.6em;
	}

	&:hover,&:focus {
		transform: scale(1.05);
		box-shadow: 5px 5px 25px rgba(0,0,0,0.16)
	}
	@media (hover: none) and (pointer: coarse) {
		&:hover {
			transform: none;
			box-shadow: none;
		}
		&:focus {
			transform: scale(1.05);
			box-shadow: 5px 5px 25px rgba(0,0,0,0.16)
		}
	}
	&.small {
		padding: 0.9em 1em;
	}
	&.has-icon {
		.icon {
			float: right;
			font-size: 20px;
			margin-top: -4px;
		}
	}
	&.full-width {
		display: block;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
	&[disabled=disabled] {
		&:hover, &:focus {
			transform:none;
			box-shadow: none;
		}
	}
}
[data-whatinput="touch"] .button {
	&:hover, &:focus {
		transform: none;
	}
}
@each $colorName, $colorValue in $colors {
	.button--#{$colorName}{
		@include transition(all);
		@if $colorName == 'white' {
			color: $dark-gray;
			background:$white;
		}@else {
			background:$colorValue;
			color:$white;
		}
		&:hover,&:focus{
			background:#{$colorValue};
			@if $colorName == 'white' {
				color: $dark-gray;
				background-color: darken($white,5%);
			}@else {
				color: $white;
				background-color: darken($colorValue,3%)
			}
		}
		&.hollow {
			background: transparent !important;
			border-color: $colorValue;
			color: $colorValue;
			&:hover,&:focus {
				border-color: $colorValue;
				color: $colorValue;
			}
		}
	}
}
.button--white {
	&.hollow {
		border-color: $white;
		color: $white;
		&:hover,&:focus{
			border-color: $white;
			color: $white;
			background-color: rgba(255,255,255,0.1);
			//border-color: darken($colorValue, 15%);
			//color: darken($colorValue,15%);
		}
	}
}
p,
.buttons {
	.button {
		& + .button {
			margin-left: rem-calc(20);
		}
	}
}
