.page-banner {
    $p: &;
    //background: $black;
    position: relative;
    z-index: 0;
    color: $white;
    padding-top: 0.625rem;
    padding-bottom: 38px;
    @include breakpoint(large) {
        padding-top: 38px;
        margin-bottom: 53px !important;
    }


    @include breakpoint(1260) {
        //padding: 38px 0.825vw;
    }
    @include breakpoint(1320) {
        padding: 38px 23px !important;
    }

    .inner {
        overflow: hidden;
        position: relative;
        height: 100%;
        @include border-radius($global-radius);
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.16);
    }


    &--small {
        position: relative;

        .inner {
            height: 200px;
            @include breakpoint(medium) {
                height: 350px;
            }
        }
    }

    #{$p}__content {
        top: 166px;
        @include breakpoint(small only) {
            //top: 20%;
            top: calc(50%);
            //margin-top: 15px;
            transform: translateY(-50%);
        }

        h1 {
            vertical-align: middle;
            font-size: rem-calc(30);
            line-height: rem-calc(34);
            @include breakpoint(medium) {
                font-size: rem-calc(40);
                line-height: rem-calc(44);
            }
            @include breakpoint(large) {
                font-size: rem-calc(70);
                line-height: rem-calc(74);
            }
        }

        h2 {
            font-size: 20px;
            line-height: 20px;

        }

        @include breakpoint(medium) {
            h1 {
                line-height: 41px;
            }
            h2 {

                font-size: 40px;
                line-height: 41px;
            }
        }
    }

    .background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        //opacity: 0.8;
        .gradient {
            width: 100%;
            height: 100%;
            @include linear-gradient--vertical(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1));
            opacity: 0.3;
        }
        .caption {
            position: absolute;
            right: 30px;
            bottom: 10px;

            font-size: 12px;

        }
    }
}