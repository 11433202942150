.illustrations {
  .illustration {
    display: none;
    @include breakpoint(large) {
      display: block;
      &.left {
        left: -30px;
      }

      @for $i from 1 through 5 {
        .left--#{$i} {
          transform: translateX((calc(-#{$i} * 35px)));
        }
        .right--#{$i} {
          transform: translateX((calc(#{$i} * 35px)));
        }
      }

      &.right {
        right: -30px;
      }

      &.middle {
        @include vertical-center();
      }

      &.bottom {
        bottom: 0;
      }

      &.top {
        top: 0;
      }
    }
  }
}

[data-block] {
  > .grid-container {
    position: relative;
  }
}