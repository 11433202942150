.submenu-container {
	@include breakpoint(large) {
		position: absolute;
		left: 0;
		right: 0;
		height: 0;
		top: 0;
		z-index: 1;
		> .grid-x {
			height: 0;
		}
	}
  .menu {
    display: block;
    border-radius: $global-radius;
    overflow: hidden;
    background: $white;
    @include box-shadow($global-shadow);
    a {
      color: $dark-gray;
      padding: rem-calc(22 25);
    }
    li:nth-child(odd) {
      background-color: rgba($medium-gray, 0.15);
    }
    li:first-child {
      background: $primary-color;
      > a {
        color: $white;
        font-weight: 700;
        font-size: rem-calc(20);
        padding: rem-calc(21 25);
      }
    }
    .is-active {
      > a {
        background: transparent;
        font-weight: 700;
      }
    }
    & + .sidebar-item {
      margin-top: 30px;
    }
  }
  .sidebar-item {
    & + .sidebar-item {
      margin-top: 30px;
    }
  }

}