.steps-list {
  .list-item {


    > .list-illustration {
      img {
        width: 75px;
        margin-top: -30px;
        @include breakpoint(medium) {
          width: 130px;
        }
      }

      position: relative;
      @include breakpoint(large) {
        width: 130px;
        .illustration {
          width: 130px;
        }
      }
      .dotted-line {
        background-image: url(../images/svg/dot-line-dark-vertical.svg);
        position: absolute;
        width: 4px;
        left: 47%;
        bottom: 20px;
        top: 13vw;
        @include breakpoint(medium) {
          margin-left: -5px;
          bottom: 20px;
          top: 120px;
          left: 50%;
        }
        @include breakpoint(large) {
          bottom: 20px;
          top: 130px;
        }
      }
    }

    .list-text {
      width: auto;

      padding-bottom: rem-calc(40);
      @include breakpoint(large) {
        padding-top: rem-calc(10);
        padding-bottom: rem-calc(115);
      }
    }

    &-last {

      .list-text {
        padding-bottom: 0;
      }
    }
  }
}