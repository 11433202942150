.header {
  &-has-border {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 35%;
      right: 35%;
      height: 4px;
      background: url(../images/svg/dot-line-dark.svg);
      background-position: center center;
      background-repeat: repeat-x;
      background-size: 11px 4px;
    }
  }
}